<template>
  <div class="world-country-zone-list">
    <v-card
      max-width="600"
      class="mx-auto mt-2 mb-2 d-flex align-stretch flex-column"
    >
      <v-row no-gutters align="center" justify="center" class="py-1">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="align-center">
              <h3 style="word-break: normal" class="align-center">
                {{ worldCountryName | capitalize }} : liste des zones
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn
                v-for="(worldCountryZoneType, index) in worldCountryZoneType"
                :key="index"
                x-small
                tile
                class="ml-1 px-1 text-lowercase white--text"
                text
                :disabled="worldCountryZoneTypeChoice != worldCountryZoneType"
              >
                {{ worldCountryZoneType }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card>
    <div v-if="dataReady">
      <CategoryList
        :key="$router.fullPath"
        nextCollection="worldCountryZoneTheme"
        paramz="setWorldCountryZone"
        listz="listZone"
      />
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
  </div>
</template>

<script>
import CategoryList from "@/component/list/ui/Category.vue";
import InfiniteLoading from "vue-infinite-loading";
import Vuex from "vuex";

export default {
  components: {
    InfiniteLoading,
    CategoryList,
  },
  name: "world-country-zone-list",
  props: ["nextCollection"],
  data: () => ({
    zoneName: "",
    worldCountryZoneTypeChoice: "Commune",
    dataReady: false,
  }),
  methods: {
    async infiniteHandler($state) {
      await this.$store.dispatch("vote/getList", {
        loading: $state,
        typeList: "zone",
        pathList: "country/" + this.$store.state.list.worldCountry + "/zone",
      });
      this.dataReady = true;
    },
  },
  computed: {
    ...Vuex.mapState("list", ["worldCountryName"]),
    ...Vuex.mapState("vote", ["worldCountryZoneType"]),
  },
  mounted: function () {
    this.$store.getters["list/breadcrumb"];
  },
};
</script>

<style>
.v-application--is-ltr .v-expansion-panel-header {
  text-align: center;
}
.v-application--is-rtl .v-expansion-panel-header {
  text-align: center;
}
</style>
